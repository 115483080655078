<template>
  <v-container>
    <!-- Header -->
    <v-row class="d-flex justify-center align-center ma-2">
      <v-icon large left color="green">mdi-home-circle-outline</v-icon>
      <div class="text-h5 pa-4 green--text darken-2">รายการเวชภัณฑ์คงคลัง</div>
      <v-spacer />
      <div class="px-2 blue--text text--darken-2">
        รายการที่เลือก {{ countSelected }} รายการ
      </div>
      <v-menu>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            tile
            large
            color="blue darken-2 white--text"
          >
            <v-icon class="pa-2">mdi-menu</v-icon>
            เลือกคำสั่ง
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn
              elevation="0"
              tile
              large
              class="white d-flex justify-start"
              width="100%"
              @click="dialogs.stock.show = true"
            >
              <v-icon class="pr-2">mdi-cog</v-icon>
              ดูขีดจำกัดคลัง
            </v-btn>
          </v-list-item>
          <!-- TODO -->
          <!-- <v-list-item>
              <v-btn elevation="0" tile large class="white d-flex justify-start" width="100%" @click="applyRouterLink($routes.STOCKCOUNT_DETAIL.name)">
                  <v-icon class="pr-2">mdi-cog</v-icon>
                  ตรวจสอบจำนวนเวชภัณฑ์
              </v-btn>
          </v-list-item> -->

          <!-- <v-list-item>
            <v-btn
              elevation="0"
              tile
              large
              class="white d-flex justify-start"
              width="100%"
              @click="dialogs.config.show = true"
            >
              <v-icon class="pr-2">mdi-cog</v-icon>
              ตั้งค่าแสดงผลคลัง
            </v-btn>
          </v-list-item> -->
          <v-list-item>
            <v-divider />
          </v-list-item>
          <v-list-item>
            <v-btn
              elevation="0"
              tile
              large
              class="white d-flex justify-start"
              width="100%"
              @click="applyRouterLink($routes.RB301.name)"
            >
              <v-icon class="pr-2">mdi-printer</v-icon>
              พิมพ์ รบ.301 (ทั้งหมด)
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              elevation="0"
              tile
              large
              class="white d-flex justify-start"
              width="100%"
              @click="
                applyRouterLink($routes.RB301.name, { selected: selectedItems })
              "
              :disabled="countSelected == 0"
            >
              <v-icon class="pr-2">mdi-printer</v-icon>
              พิมพ์ รบ.301 (รายการที่เลือก)
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              elevation="0"
              tile
              large
              class="white d-flex justify-start"
              width="100%"
              @click="
                applyRouterLink($routes.STOCK_CARD.name, {
                  selected: selectedItems,
                })
              "
              :disabled="countSelected == 0"
            >
              <v-icon class="pr-2">mdi-printer</v-icon>
              พิมพ์ใบคุมคลัง (รายการที่เลือก)
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              elevation="0"
              tile
              large
              class="white d-flex justify-start"
              width="100%"
              @click="getExcel"
            >
              <v-icon class="pr-2">mdi-printer</v-icon>
              พิมพ์ใบสรุปมูลค่าคงคลัง
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-divider />

    <v-text-field
      outlined
      label="ค้นหารายการ"
      class="mt-2"
      v-model="searchText"
    ></v-text-field>

    <!-- TABLE -->
    <v-tabs grow v-model="tab">
      <v-tab
        class="title"
        active-class="indigo lighten-2 white--text rounded-top"
        >เวชภัณฑ์ยา</v-tab
      >
      <v-tab class="title" active-class="blue lighten-2 white--text rounded-top"
        >เวชภัณฑ์ที่มิใช่ยา</v-tab
      >
      <v-tab
        class="title"
        active-class="green lighten-2 white--text rounded-top"
        >สมุนไพร</v-tab
      >
      <v-tab
        class="title"
        active-class="yellow darken-2 white--text rounded-top"
        >อื่นๆ</v-tab
      >
      <v-tabs-slider color="white"></v-tabs-slider>
    </v-tabs>

    <!-- <v-divider class="blue darken-2" /> -->

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <!-- Drug -->
        <v-data-table
          :items-per-page="10"
          :items="getMedItems"
          :headers="inventoryTable.headers.med"
          group-by="genericName"
          :expanded.sync="inventoryTable.expandedRows"
          :footer-props="{
            'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
            pageText: 'แสดง {0}-{1} จาก {2}',
          }"
        >
          <template v-slot:item.quantity="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <div v-if="item.status == 0" class="orange--text">
                    {{ parseInt(item.quantity) }} {{ item.unit }}
                  </div>
                  <div v-else>
                    {{ parseInt(item.quantity) }} {{ item.unit }}
                  </div>
                </span>
              </template>
              <div v-if="item.status == 0">อยู่ในระหว่างการจัดส่ง</div>
              <div v-else>เวชภัณฑ์คงคลัง</div>
            </v-tooltip>
          </template>

          <template v-slot:item.tpuName="{ item }">
            {{ item.tpuName }} (ล็อต {{ item.lotNo }})
          </template>

          <template v-slot:group.header="{ group, items, isOpen, toggle }">
            <th colspan="1" class="text-body-1">
              <div class="d-flex flex-row align-center">
                <v-icon @click="toggle" :is-expanded="isOpen">{{
                  isOpen ? "mdi-minus" : "mdi-plus"
                }}</v-icon>
                <v-checkbox class="px-4" v-model="items[0].check"></v-checkbox>
                {{ items[0].genericName }}
              </div>
            </th>
            <th colspan="1" class="text-body-1">
              {{ items[0].packingSize }}
            </th>
            <th colspan="1" class="text-body-1">
              <div v-if="isPendingIncluded(items)" class="orange--text">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"
                      >{{ sumQuantity(items) }} {{ items[0].unit }}*</span
                    >
                  </template>
                  <v-row
                    class="d-flex flex-row pa-4 align-center justify-center"
                  >
                    <v-icon color="white" class="mr-2">mdi-warehouse</v-icon>
                    <span class="mr-2"
                      >{{ onHandCount(items) }} {{ items[0].unit }}</span
                    >
                    <v-icon color="white" class="mr-2">mdi-truck</v-icon>
                    <span>{{ incomingCount(items) }} {{ items[0].unit }}</span>
                  </v-row>
                </v-tooltip>
              </div>
              <div v-else>{{ sumQuantity(items) }} {{ items[0].unit }}</div>
            </th>
            <th colspan="1" class="text-body-1">
              {{ items[0].unitPrice }}
            </th>
            <th colspan="1" class="text-body-1">
              {{ items[0].recived }}
            </th>
            <th colspan="1" class="text-body-1">
              {{ items[0].expired }}
            </th>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <!-- Non-Drug -->
        <v-data-table
          :items-per-page="10"
          :items="getNonmedItems"
          :headers="inventoryTable.headers.nonmed"
          group-by="genericName"
          :expanded.sync="inventoryTable.expandedRows"
          :footer-props="{
            'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
            pageText: 'แสดง {0}-{1} จาก {2}',
          }"
        >
          <template v-slot:group.header="{ group, items, isOpen, toggle }">
            <th colspan="1" class="text-body-1">
              <div class="d-flex flex-row align-center">
                <v-icon @click="toggle" :is-expanded="isOpen">{{
                  isOpen ? "mdi-minus" : "mdi-plus"
                }}</v-icon>
                <v-checkbox class="px-4" v-model="items[0].check"></v-checkbox>
                {{ items[0].genericName }}
              </div>
            </th>
            <th colspan="1" class="text-body-1">
              {{ items[0].packingSize }}
            </th>
            <th colspan="4" class="text-body-1">
              <div v-if="isPendingIncluded(items)" class="orange--text">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"
                      >{{ sumQuantity(items) }} {{ items[0].unit }}*</span
                    >
                  </template>
                  <v-row
                    class="d-flex flex-row pa-4 align-center justify-center"
                  >
                    <v-icon color="white" class="mr-2">mdi-warehouse</v-icon>
                    <span class="mr-2"
                      >{{ onHandCount(items) }} {{ items[0].unit }}</span
                    >
                    <v-icon color="white" class="mr-2">mdi-truck</v-icon>
                    <span>{{ incomingCount(items) }} {{ items[0].unit }}</span>
                  </v-row>
                </v-tooltip>
              </div>
              <div v-else>{{ sumQuantity(items) }} {{ items[0].unit }}</div>
            </th>
          </template>
          <template v-slot:item.quantity="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <div v-if="item.status == 0" class="orange--text">
                    {{ parseInt(item.quantity) }} {{ item.unit }}
                  </div>
                  <div v-else>
                    {{ parseInt(item.quantity) }} {{ item.unit }}
                  </div>
                </span>
              </template>
              <div v-if="item.status == 0">อยู่ในระหว่างการจัดส่ง</div>
              <div v-else>เวชภัณฑ์คงคลัง</div>
            </v-tooltip>
          </template>
          <template v-slot:item.tpuName="{ item }">
            {{ item.tpuName }}
            <span v-if="item.lotNo">(ล็อต {{ item.lotNo }})</span>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <!-- Herb -->
        <v-data-table
          :items-per-page="10"
          :items="getHerbItems"
          :headers="inventoryTable.headers.herb"
          group-by="genericName"
          :expanded.sync="inventoryTable.expandedRows"
          :footer-props="{
            'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
            pageText: 'แสดง {0}-{1} จาก {2}',
          }"
        >
          <template v-slot:group.header="{ group, items, isOpen, toggle }">
            <th colspan="1" class="text-body-1">
              <div class="d-flex flex-row align-center">
                <v-icon @click="toggle" :is-expanded="isOpen">{{
                  isOpen ? "mdi-minus" : "mdi-plus"
                }}</v-icon>
                <v-checkbox class="px-4" v-model="items[0].check"></v-checkbox>
                {{ items[0].genericName }}
              </div>
            </th>
            <th colspan="1" class="text-body-1">
              {{ items[0].packingSize }}
            </th>
            <th colspan="4" class="text-body-1">
              <div v-if="isPendingIncluded(items)" class="orange--text">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ sumQuantity(items) }} {{ items[0].unit }}*</span
                    >
                  </template>
                  <v-row
                    class="d-flex flex-row pa-4 align-center justify-center"
                  >
                    <v-icon color="white" class="mr-2">mdi-warehouse</v-icon>
                    <span class="mr-2"
                      >{{ onHandCount(items) }} {{ items[0].unit }}</span
                    >
                    <v-icon color="white" class="mr-2">mdi-truck</v-icon>
                    <span>{{ incomingCount(items) }} {{ items[0].unit }}</span>
                  </v-row>
                </v-tooltip>
              </div>
              <div v-else>{{ sumQuantity(items) }} {{ items[0].unit }}</div>
            </th>
          </template>
          <template v-slot:item.quantity="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <div v-if="item.status == 0" class="orange--text">
                    {{ parseInt(item.quantity) }} {{ item.unit }}
                  </div>
                  <div v-else>
                    {{ parseInt(item.quantity) }} {{ item.unit }}
                  </div>
                </span>
              </template>
              <div v-if="item.status == 0">อยู่ในระหว่างการจัดส่ง</div>
              <div v-else>เวชภัณฑ์คงคลัง</div>
            </v-tooltip>
          </template>
          <template v-slot:item.tpuName="{ item }">
            {{ item.tpuName }}
            <span v-if="item.lotNo">(ล็อต {{ item.lotNo }})</span>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <!-- Others -->
        <v-data-table
          :items-per-page="10"
          :items="getOtherItems"
          :headers="inventoryTable.headers.other"
          group-by="genericName"
          :expanded.sync="inventoryTable.expandedRows"
          :footer-props="{
            'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
            pageText: 'แสดง {0}-{1} จาก {2}',
          }"
        >
          <template v-slot:group.header="{ group, items, isOpen, toggle }">
            <th colspan="1" class="text-body-1">
              <div class="d-flex flex-row align-center">
                <v-icon @click="toggle" :is-expanded="isOpen">{{
                  isOpen ? "mdi-minus" : "mdi-plus"
                }}</v-icon>
                <v-checkbox class="px-4" v-model="items[0].check"></v-checkbox>
                {{ items[0].genericName }}
              </div>
            </th>
            <th colspan="1" class="text-body-1">
              {{ items[0].packingSize }}
            </th>
            <th colspan="4" class="text-body-1">
              <div v-if="isPendingIncluded(items)" class="orange--text">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"
                      >{{ sumQuantity(items) }} {{ items[0].unit }}*</span
                    >
                  </template>
                  <v-row
                    class="d-flex flex-row pa-4 align-center justify-center"
                  >
                    <v-icon color="white" class="mr-2">mdi-warehouse</v-icon>
                    <span class="mr-2"
                      >{{ onHandCount(items) }} {{ items[0].unit }}</span
                    >
                    <v-icon color="white" class="mr-2">mdi-truck</v-icon>
                    <span>{{ incomingCount(items) }} {{ items[0].unit }}</span>
                  </v-row>
                </v-tooltip>
              </div>
              <div v-else>{{ sumQuantity(items) }} {{ items[0].unit }}</div>
            </th>
          </template>
          <template v-slot:item.quantity="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <div v-if="item.status == 0" class="orange--text">
                    {{ parseInt(item.quantity) }} {{ item.unit }}
                  </div>
                  <div v-else>
                    {{ parseInt(item.quantity) }} {{ item.unit }}
                  </div>
                </span>
              </template>
              <div v-if="item.status == 0">อยู่ในระหว่างการจัดส่ง</div>
              <div v-else>เวชภัณฑ์คงคลัง</div>
            </v-tooltip>
          </template>
          <template v-slot:item.tpuName="{ item }">
            {{ item.tpuName }}
            <span v-if="item.lotNo">(ล็อต {{ item.lotNo }})</span>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <!-- Stock Dialog -->
    <v-dialog width="70%" v-model="dialogs.stock.show">
      <v-card>
        <v-card-title class="headline blue darken-2 white--text"
          >ขีดจำกัดคลัง</v-card-title
        >
        <!-- Stock General Info -->
        <v-card>
          <div class="mx-2">
            <v-data-table
              :items="dialogs.stock.table.items"
              :headers="dialogs.stock.table.header"
              :items-per-page="5"
              :footer-props="{
                'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
                pageText: 'แสดง {0}-{1} จาก {2}',
              }"
            >
            </v-data-table>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              tile
              rounded
              color="blue darken-2 white--text"
              @click="dialogs.stock.show = false"
              >เสร็จสิ้น</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Config Dialog -->
    <v-dialog width="700" v-model="dialogs.config.show">
      <v-card>
        <v-card-title class="headline blue darken-2 white--text"
          >ตั้งค่าแสดงผลคลัง</v-card-title
        >
        <!-- Edit General Info -->
        <v-card>
          <v-card-text>
            <div>
              <v-checkbox
                label="แสดงรายการที่ไม่มีในคลัง"
                v-model="inventoryTable.showEmpty"
              ></v-checkbox>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              tile
              rounded
              color="blue darken-2 white--text"
              @click="dialogs.config.show = false"
              >เสร็จสิ้น</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { saveAs } from "file-saver";
const axios = require("axios").default;
// const url = "http://localhost:8910/api";
// const url = "http://anantasolutions.co/flagship/api"
const url = "http://164.115.22.214/api";

export default {
  data: () => {
    return {
      ready: false,
      dialogs: {
        stock: {
          show: false,
          table: {
            header: [],
            items: [],
          },
        },
        config: {
          show: false,
        },
      },
      tab: null,
      searchText: "",
      inventoryTable: {
        expandedRows: [],
        showEmpty: false,
        headers: {
          med: [],
          nonmed: [],
          herb: [],
          other: [],
        },
        items: {
          med: [],
          nonmed: [],
          herb: [],
          other: [],
        },
      },

      detailedTableHeader: [
        {
          text: "ชื่อ",
          value: "genericName",
        },
        {
          text: "ชื่อ",
          value: "tpuName",
        },
        {
          text: "ขนาดบรรจุ",
          value: "packingSize",
        },
        {
          text: "จำนวนคงเหลือ (หน่วย)",
          value: "quantity",
        },
        {
          text: "ราคาต่อหน่วย (บาท)",
          value: "unitPrice",
        },
        {
          text: "วันที่รับเข้าระบบ",
          value: "recived",
        },
        {
          text: "วันหมดอายุ",
          value: "expired",
        },
      ],
      basicTableHeader: [
        {
          text: "ชื่อ",
          value: "genericName",
        },
        {
          text: "ขนาดบรรจุ",
          value: "packingSize",
        },
        {
          text: "จำนวนคงเหลือ (หน่วย)",
          value: "quantity",
        },
        {
          text: "ราคาต่อหน่วย",
          value: "unitPrice",
        },
      ],
      stockDialogTableHeader: [
        { text: "ชื่อสามัญ", value: "name" },
        { text: "ประเภท", value: "type" },
        { text: "ปริมาณสั่งซื้อซ้ำ", value: "reorder" },
        { text: "ขีดจำกัดคลัง", value: "stocklimit" },
      ],
      inventoryNonDrugHeader: [
        {
          text: "ชื่อ",
          value: "genericName",
        },
        {
          text: "ชื่อ",
          value: "tpuName",
        },
        {
          text: "ขนาดบรรจุ",
          value: "packingSize",
        },
        {
          text: "จำนวนคงเหลือ (หน่วย)",
          value: "quantity",
        },
        {
          text: "ราคาต่อหน่วย",
          value: "unitPrice",
        },
        {
          text: "วันที่รับเข้าระบบ",
          value: "recived",
        },
        {
          text: "วันหมดอายุ",
          value: "expired",
        },
      ],
      inventoryHerbHeader: [
        {
          text: "ชื่อ",
          value: "genericName",
        },
        {
          text: "ชื่อ",
          value: "tpuName",
        },
        {
          text: "ขนาดบรรจุ",
          value: "packingSize",
        },
        {
          text: "จำนวนคงเหลือ (หน่วย)",
          value: "quantity",
        },
        {
          text: "ราคาต่อหน่วย",
          value: "unitPrice",
        },
        {
          text: "วันที่รับเข้าระบบ",
          value: "recived",
        },
        {
          text: "วันหมดอายุ",
          value: "expired",
        },
      ],
      inventoryOtherHeader: [
        {
          text: "ชื่อ",
          value: "name",
        },
        {
          text: "ขนาดบรรจุ",
          value: "packingSize",
        },
        {
          text: "จำนวนคงเหลือ (หน่วย)",
          value: "quantity",
        },
        {
          text: "ราคาต่อหน่วย",
          value: "unitPrice",
        },
      ],

      // Example Data
      itemDrugList: [],
      itemNonDrugList: [],
      itemHerbList: [],
      itemOtherList: [],
      editItemList: [],
      selectedItems: [],
    };
  },
  methods: {
    applyRouterLink(name, params) {
      this.$router.push({ name, params: params });
    },
    initialize() {
      this.inventoryTable.headers.med = this.detailedTableHeader;
      this.inventoryTable.headers.nonmed = this.detailedTableHeader;
      this.inventoryTable.headers.herb = this.detailedTableHeader;
      this.inventoryTable.headers.other = this.detailedTableHeader;
      this.dialogs.stock.table.header = this.stockDialogTableHeader;

      // add function to this

      this.getHospAvailable();
      this.getHospData();

      this.inventoryTable.expandedRows = [];
    },
    closeAllPanel() {
      this.inventoryTable.expandedRows = [];
    },
    sumQuantity(items) {
      var sumQuan = 0;
      items.forEach((e) => {
        sumQuan += parseInt(e.quantity);
      });
      return sumQuan;
    },
    isPendingIncluded(items) {
      var isPending = false;
      items.forEach((e) => {
        if (e.status == 0) {
          isPending = true;
        }
      });
      return isPending;
    },
    onHandCount(items) {
      var cnt = 0;
      items.forEach((e) => {
        if (e.status == 1) {
          cnt += parseInt(e.quantity);
        }
      });
      return cnt;
    },
    incomingCount(items) {
      var cnt = 0;
      items.forEach((e) => {
        if (e.status == 0) {
          cnt += parseInt(e.quantity);
        }
      });
      return cnt;
    },
    getHospAvailable() {
      // get available list of supply
      // get possible stock
      let org_id = this.$cookies.get("user").hosp_code;
      axios.post(url + "/assignment/list", { pc_id: org_id }).then((res) => {
        // console.log("getHospAssignment");
        // console.log(res.data);
        this.editItemList = new Array();
        var result = res.data;
        result.forEach((r) => {
          var gen_type;
          switch (r.generic_type) {
            case "med":
              gen_type = "ยา";
              break;
            case "nonmed":
              gen_type = "เวชภัณฑ์มิใช่ยา";
              break;
            case "herb":
              gen_type = "สมุนไพร";
              break;
            case "other":
            default:
              gen_type = "อื่น ๆ";
              break;
          }
          var item = {
            id: r.generic_code,
            name: r.generic_name,
            dosage: r.dosage,
            packingSize: r.packingSize,
            unit: r.unit,
            type: gen_type,
            reorder: r.min,
            stocklimit: r.max,
          };
          this.editItemList.push(item);
        });
        // console.log("This Stock List:");
        // console.log(this.editItemList);
        this.dialogs.stock.table.items = this.editItemList;
      });
    },
    getHospData() {
      let org_id = this.$cookies.get("user").hosp_code;

      // get hospital inventory data
      axios
        .post(url + "/inventory/records", {
          org_id: org_id,
        })
        .then((res) => {
          // console.log("getHospInventory");
          // console.log(res.data);
          var hospData = res.data.inventory;
          hospData.forEach((inv) => {
            var log = inv.logs;
            log.forEach((l) => {
              // console.log(l);
              if (l.log_status == 1 || l.log_status == 4) {
                var item = {
                  genericId: inv.generic_id,
                  genericName: inv.generic_name,
                  dosage: inv.dosage,
                  packingSize: inv.packingSize,
                  location: "-",
                  status: 1,
                  check: false,
                  tpuName: l.trade_name,
                  unit: l.lot_uom,
                  lotNo: l.lot_no,
                  expired: l.expiry_date
                    ? new Date(l.expiry_date).toLocaleDateString("th-TH")
                    : "-",
                  recived: l.updated_at
                    ? new Date(l.updated_at).toLocaleDateString("th-TH")
                    : "=",
                  unitPrice: l.price_per_unit,
                  quantity: l.amount,
                };
                // console.log(item);
                if (inv.generic_type == "med") {
                  this.itemDrugList.push(item);
                } else if (inv.generic_type == "nonmed") {
                  this.itemNonDrugList.push(item);
                } else if (inv.generic_type == "herb") {
                  this.itemHerbList.push(item);
                } else if (inv.generic_type == "other") {
                  this.itemOtherList.push(item);
                }
              }
            });
          });
        })
        .then(() => {
          // console.log(this.itemDrugList);
          this.inventoryTable.items.med = this.itemDrugList;
          this.inventoryTable.items.nonmed = this.itemNonDrugList;
          this.inventoryTable.items.herb = this.itemHerbList;
          this.inventoryTable.items.other = this.itemOtherList;

          this.ready = true;
        });

      // get hospital incoming supply data
      axios
        .post(url + "/pcoc/get_asn", {
          hosp_code: org_id,
        })
        .then((response) => {
          console.log(response.data);
          response.data.asns.forEach((asn) => {
            if (asn.status == 1000) {
              // console.log(asn.items);
              asn.items.forEach((item) => {
                // console.log(item);
                if (
                  (item.lot_qty > 0 && item.status == 1000) ||
                  item.status == 1002
                ) {
                  var incoming = {
                    genericId: item.generic_id,
                    genericName: item.generic_name,
                    dosage: item.dosage,
                    packingSize: item.packingSize,
                    location: "-",
                    status: 0,
                    check: false,
                    tpuName: item.trade_name,
                    unit: item.lot_uom,
                    lotNo: item.lot_number,
                    expired: item.expiry_date
                      ? new Date(item.expiry_date).toLocaleDateString("th-TH")
                      : "-",
                    recived: item.received_at
                      ? new Date(item.received_at).toLocaleString("th-TH")
                      : "-",
                    unitPrice: item.price_per_unit,
                    quantity: item.lot_qty,
                  };
                  // console.log(incoming);
                  if (item.type == "med") {
                    this.itemDrugList.push(incoming);
                  } else if (item.type == "nonmed") {
                    this.itemNonDrugList.push(incoming);
                  } else if (item.type == "herb") {
                    this.itemHerbList.push(incoming);
                  } else if (item.type == "other") {
                    this.itemOtherList.push(incoming);
                  }
                }
              });
            }
          });
        })
        .then(() => {
          // console.log(this.itemDrugList);
          this.inventoryTable.items.med = this.itemDrugList;
          this.inventoryTable.items.nonmed = this.itemNonDrugList;
          this.inventoryTable.items.herb = this.itemHerbList;
          this.inventoryTable.items.other = this.itemOtherList;

          this.ready = true;
        });

      console.log(this.itemDrugList);
      // end here
    },
    getExcel() {
      // const axios = require("axios");
      let hosp_code = this.$cookies.get("user").hosp_code;
      let hosp_name = this.$cookies.get("user").hosp_name;
      // let data = JSON.stringify({
      //   hosp_code: hosp_code,
      //   hosp_name: hosp_name,
      // });
      // console.log(data);

      let getdate = new Date();
      let day = getdate.toLocaleDateString("th-TH", { day: "numeric" });
      let month = getdate.toLocaleDateString("th-TH", { month: "short" });
      let year = getdate
        .toLocaleDateString("th-TH", { year: "numeric" })
        .substring(5);
      let datetobe = day + month + year;
      let filename =
        "สรุปรายการเวชภัณฑ์_" + hosp_name + "_" + datetobe + ".xlsx";

      filename
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: url + "/pcoc/getCumExcelFile?hosp_code=" + hosp_code,
        responseType: "arraybuffer",
      };

      axios
        .request(config)
        .then((response) => {
          saveAs(new Blob([response.data],{
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }), filename);
        })
        .catch((error) => {
          console.log(error);
        });

      // let hosp_code = this.$cookies.get("user").hosp_code;
      // let hosp_name = this.$cookies.get("user").hosp_name;
      // let data = JSON.stringify({
      //   hosp_code: hosp_code,
      //   hosp_name: hosp_name,
      // });

      // let getdate = new Date();
      // let day = getdate.toLocaleDateString("th-TH", { day: "numeric" });
      // let month = getdate.toLocaleDateString("th-TH", { month: "short" });
      // let year = getdate
      //   .toLocaleDateString("th-TH", { year: "numeric" })
      //   .substring(5);
      // let datetobe = day + month + year;
      // let filename =
      //   "สรุปรายการเวชภัณฑ์_" + hosp_name + "_" + datetobe + ".xlsx";

      // let config = {
      //   method: "get",
      //   maxBodyLength: Infinity,
      //   url: url + "/pcoc/getCumExcelFile",
      //   headers: {
      //     "Content-Disposition": "attachment; filename=${filename}",
      //     "Content-Type":
      //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //     responseType: "arraybuffer",
      //   },
      //   data: data,
      // };

      // axios
      //   .request(config)
      //   .then((response) => {
      //     saveAs(
      //       new Blob([response.data], {
      //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //       }),
      //       filename
      //     );
      //     // var FILE = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
      //     // var docUrl = document.createElement("a");
      //     // docUrl.href = FILE;
      //     // docUrl.setAttribute("download", filename);
      //     // document.body.appendChild(docUrl);
      //     // docUrl.click();
      //     // setTimeout( () => {
      //     //   document.body.removeChild(docUrl);
      //     //   window.URL.revokeObjectURL(FILE);
      //     // }, 2000)
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios({
      //   url: "/pcoc/getCumExcelFile", // File URL Goes Here
      //   method: "POST",
      //   responseType: "blob",
      // }).then((res) => {
      //   var FILE = window.URL.createObjectURL(new Blob([res.data]));

      //   var docUrl = document.createElement("x");
      //   docUrl.href = FILE;
      //   docUrl.setAttribute("download", filename);
      //   document.body.appendChild(docUrl);
      //   docUrl.click();
      // });
    },
  },
  computed: {
    countSelected() {
      var cnt = 0;
      // this.selectedItems = [];
      this.inventoryTable.items.med.forEach((e) => {
        if (e.check) {
          this.selectedItems.push(e.genericId);
          cnt += 1;
        }
      });
      this.inventoryTable.items.nonmed.forEach((e) => {
        if (e.check) {
          this.selectedItems.push(e.genericId);
          cnt += 1;
        }
      });
      this.inventoryTable.items.herb.forEach((e) => {
        if (e.check) {
          this.selectedItems.push(e.genericId);
          cnt += 1;
        }
        if (e.check) cnt += 1;
      });
      return cnt;
    },
    getMedItems() {
      var data = this.inventoryTable.items.med;
      if (this.searchText != "") {
        data = data.filter((e) => {
          console.log(e);
          return e.genericName.toLowerCase().includes(this.searchText);
        });
      }
      return data;
    },
    getNonmedItems() {
      var data = this.inventoryTable.items.nonmed;
      if (this.searchText != "") {
        data = data.filter((e) => {
          return e.genericName.toLowerCase().includes(this.searchText);
        });
      }
      return data;
    },
    getHerbItems() {
      var data = this.inventoryTable.items.herb;
      if (this.searchText != "") {
        data = data.filter((e) => {
          return e.genericName.toLowerCase().includes(this.searchText);
        });
      }
      return data;
    },
    getOtherItems() {
      var data = this.inventoryTable.items.other;
      if (this.searchText != "") {
        data = data.filter((e) => {
          return e.genericName.toLowerCase().includes(this.searchText);
        });
      }
      return data;
    },
    // ||e.tpuName.includes(this.searchText)
  },
  mounted() {
    this.initialize();
    this.closeAllPanel();
  },
};
</script>